import React from "react";
import "./ankeiler.scss"
import Button from "../../components/buttons/button"
import CoachingIcon from "../../assets/svg/used/coaching.svg";
import TrainingIcon from "../../assets/svg/used/teamwork.svg";
import SpeakingIcon from "../../assets/svg/used/speaking.svg";
import Email from "../../assets/svg/used/email.svg";
import Handshake from "../../assets/svg/used/handshake.svg";
import Calendar from "../../assets/svg/used/calendar.svg";
import Puzzlehead from "../../assets/svg/used/puzzlehead.svg";
import Finding from "../../assets/svg/used/finding.svg";
import ArrowRight from "../../assets/svg/icons-feather/arrow-right.svg"
import {Link} from "gatsby";

const Ankeiler = (props) => {

  // Note: why not use the <Icon> component here instead of below conditionals?
  // Because that will load ALL svg's which is not way too much overhead.
  // Still looking for a cleaner way though...

  let classNames = ['ankeiler'];
  if (props.style === 'simple') {
    classNames.push('simple');
  }
  // Full width ankeilers width subtitle.
  if (props.style === 'horizontal') {
    classNames.push('horizontal');
  }
  if (!props.style) {
    classNames.push('standard');
  }
  classNames = classNames.join(' ');

  // @todo refactor this, no time now, gotta cook food...
  if (props.type === 'email') {
    return (
    <a href={props.link} className={classNames}>
      <div className="ankeiler-icon">
        <Email />
      </div>
      <div className="ankeiler-content">
      <h3>{props.title}</h3>
      {props.children &&
        <p>
          {props.children}
        </p>
      }
      {props.cta &&
      <Button><ArrowRight />{props.cta}</Button>
      }
      </div>
    </a>
    )
  }

  return (
    <Link className={classNames} to={props.link} target={ props.target ?? '_self'}>
      <div className="ankeiler-icon">
      {props.icon == 'coaching' &&
        <CoachingIcon />
      }
      {props.icon == 'speaking' &&
        <TrainingIcon />
      }
      {props.icon == 'training' &&
        <SpeakingIcon />
      }
      {props.icon == 'email' &&
        <Email />
      }
      {props.icon == 'handshake' &&
        <Handshake />
      }
      {props.icon == 'calendar' &&
        <Calendar />
      }
      {props.icon == 'puzzlehead' &&
        <Puzzlehead />
      }
      {props.icon == 'finding' &&
        <Finding />
      }
      </div>
      <div className="ankeiler-content">
        <h3>{props.title}</h3>
      {props.subtitle &&
        <div className="ankeiler-subtitle">{props.subtitle}</div>
      }
        {props.children &&
          <p>
            {props.children}
          </p>
        }
        {props.cta &&
        <Button><ArrowRight />{props.cta}</Button>
        }
      </div>
    </Link>
  )
};

export default Ankeiler;
