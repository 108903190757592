import React, {useContext} from "react";
import "./services.scss"
import Ankeiler from "./ankeiler";
import {SiteContext} from "../../context/site-context";

const Services = (props) => {

  const siteContext = useContext(SiteContext);
  const i18next = siteContext.i18next;

  const { showTitle, padding } = props;
  const paddingClass = !padding ? 'no-padding' : '';

  if (i18next.language ==='nl') {
    return (
      <div className={`services ${paddingClass}`}>
        <div className="inner-content">
          { showTitle &&
            <h2>Hoe kan ik je helpen?</h2>
          }
          <Ankeiler icon="coaching" title="Coaching voor introverten" link="/nl/introvert-coaching" cta="Lees meer ">Maak van introversie jouw kracht</Ankeiler>
          <Ankeiler icon="training" title="Coaching voor hoogbegaafden" link="/nl/coaching-voor-hoogbegaafden" cta="Lees meer">Benut jouw unieke talenten</Ankeiler>
          <Ankeiler icon="speaking" title="Boek me als spreker" link="/nl/spreken-introversie" cta="Lees meer">Boek me als spreker voor events, workshops en webinars.</Ankeiler>
        </div>
      </div>
    )
  }

  if (i18next.language ==='en') {
    return (
      <div className={`services ${paddingClass}`}>
        <div className="inner-content">
          { showTitle &&
            <h2>How can I help you?</h2>
          }
          <Ankeiler icon="coaching" title="Coaching">Learn to use your full potential in my Put Your Strength Into Action program.</Ankeiler>
          <Ankeiler icon="speaking" title="Training">Create more mutual understanding by following one of my sessions on introversion and diversity.</Ankeiler>
          <Ankeiler icon="training" title="Speaking">I'm available to speak during company sessions, conferences and events.</Ankeiler>
        </div>
      </div>
    )
  }

};

export default Services;
